import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<FeedbackDialog>`}</inlineCode>{` component is a non-modal dialog that allows your users to
provide low-friction, anonymous feedback for a specific page.`}</p>
    </PageDescription>
    <h2>{`Activating the dialog`}</h2>
    <p>{`The Feedback button only becomes visible once you’ve supplied an `}<inlineCode parentName="p">{`onSubmit`}</inlineCode>{`
handler. To do that, we’ll need to shadow the `}<inlineCode parentName="p">{`FeedbackDialog`}</inlineCode>{` component.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a new javascript file under
`}<inlineCode parentName="p">{`src/gatsby-theme-carbon/components/FeedbackDialog/FeedbackDialog.js`}</inlineCode>{`.
Matching the filepath exactly is important here.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy the following snippet into your new file`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import ThemeFeedbackDialog from 'gatsby-theme-carbon/src/components/FeedbackDialog/FeedbackDialog';

const FeedbackDialog = ({ props }) => {
  const onSubmit = (data) => {
    console.log({ ...data });
  };

  return <ThemeFeedbackDialog {...props} onSubmit={onSubmit} />;
};

export default FeedbackDialog;
`}</code></pre>
    <h2>{`Creating a handler`}</h2>
    <p>{`Next, you’ll need a place to send the data. For the Carbon website, we use a
serverless function that forwards the data to a
`}<a parentName="p" {...{
        "href": "https://www.surveygizmo.com/"
      }}>{`SurveyGizmo`}</a>{` quiz. You can see that function
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/blob/master/api/survey.ts"
      }}>{`here`}</a>{`.`}</p>
    <p>{`The handler can send a fetch request off to the endpoint you create. Update the
`}<inlineCode parentName="p">{`onSubmit`}</inlineCode>{` handler to send the data wherever you want. This function receives
the following arguments:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`experience`}</inlineCode>{`: “Negative”, “Positive” or “Neutral”`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`comment`}</inlineCode>{`: An optional comment`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`path`}</inlineCode>{`: the window location when the survey was submitted`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const FeedbackDialog = ({ props }) => {
  const onSubmit = data => {
    fetch(process.env.API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(data),
  });

  return <ThemeFeedbackDialog {...props} onSubmit={onSubmit} />;
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      